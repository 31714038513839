// Type Reset of the Bootstrap styles before re-styling with Foundations custom styling
body{
    line-height:1.5;
    font-size:14px;
}
h1, h2, h3, h4, h5, h6, p, a{
    margin:0px;
}

a, a:hover, a:focus, a:visited{
    text-decoration:none;
}
a h2:hover{
    color: @brand-primary;
    text-decoration:none;
}

.quote{
    font-family: 'Playfair Display', serif;
}
  

//
// Typography
// --------------------------------------------------

// Headings
// -------------------------

.section-header{
    margin-bottom:@s-space;
}

@media (min-width: @screen-sm-min) {
    .section-header{
        margin-bottom:@m-space;
    }
}
@media (min-width: @screen-md-min) {
    .section-header{
        margin-bottom:@l-space;
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @headings-font-family;
  font-weight: @headings-font-weight;
  color: @headings-color;
  margin: @s-margin 0px;
            @media (min-width: @screen-sm-min) {
                
            }
}

h1, .h1 {
            font-size: 26px;
            font-weight: 900;
            /* Small devices (tablets, 768px and up) */
            @media (min-width: @screen-sm-min) {
                font-size: 34px;
            }
            @media (min-width: @screen-md-min) {
                font-size: 42px;
            }
            @media (min-width: @screen-lg-min) {
                font-size: 48px;
            }
        }
h2, .h2 {
            font-size: 18px;
            @media (min-width: @screen-sm-min) {
                .section-header{
                    margin-bottom:@l-space;
                }
                font-size: 22px;
            }
            @media (min-width: @screen-md-min) {
                font-size: 36px;
            }
        }
h3, .h3 { 
            font-size: 16px;
            @media (min-width: @screen-sm-min) {
                font-size: 20px;
            }
            @media (min-width: @screen-md-min) {
                font-size: 26px;
            }
        }
h4, .h4 { 
            font-size: 16px;
            @media (min-width: @screen-sm-min) {
                font-size: 22px;
            }
        }
h5, .h5 {
            font-size: 16px;
        }
h6, .h6 {
            font-size: 16px;
        }
h1, .h1,
h2, .h2,
h3, .h3 {

}

// Body text
// -------------------------

p, ul li{
    font-family: 14px;
    color:@gray;
    margin: @s-margin 0px;
            @media (min-width: @screen-sm-min) {              
                font-size: 18px;
                
            }
}
a {
    font-family: 14px;
    text-decoration:underline;
    margin: @s-margin 0px;
            @media (min-width: @screen-sm-min) { 
                font-size: 18px;
            }
}