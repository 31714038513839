// Pricing Blocks

// Pricing Module 001
.pricing-block-001{
    .box{
        padding: @s-space 0px @m-space;
        border-radius: 4px;
        .box-shadow(0px, 3px, 0px, darken(@btn-secondary-bg, 20%));
        border: 1px solid @btn-secondary-border;
    }
    .circle h3{
            background-color: #F2F2F2;
            width: 72px;
            height: 72px;
            border-radius: 80px;
            padding: 20px;
            text-align: center;
            margin: 10px auto;
            line-height: 32px;
    }
    .left-price{
        margin-bottom: @m-space;
    }
    @media (min-width: @screen-sm-min) {
        .circle h3{
            width: 102px;
            height: 102px;
            padding: 20px;
            margin: 10px auto;
            line-height: 60px;
        }
        .box{
            padding: @m-space 0px @l-space;
        }
        .left-price{
            border-right:1px solid @btn-secondary-border;
            margin-bottom: 0px;
        }
    }
}

.pricing-block-002{
    .box{
        overflow: auto;
        padding: 0px;
        border-radius: 4px;
        .box-shadow(0px, 0px, 0px, darken(@btn-secondary-bg, 20%));
        border: 1px solid @btn-secondary-border;
        p{
            padding: 10px;
            background-color: @blue-light;
            margin: 0px;
        }
        margin-bottom:10px;
    }
    ul{
        padding:0px @m-space;
    }
    ul li{
        list-style:none;
        padding: @xs-space 0px;
        margin: 0px;
        border-bottom:1px solid @gray-light;
    }
    h3 {
        background-color: @theme-primary;
        margin: 0px;
        padding: @s-space 0px;
        color:#ffffff;
    }
    .btn{
        width:100%;
        margin-bottom: 3px;
    }
}

.pricing-block-003{
    .glyphicon-ok{
        color:#00B67F;
    }
    td{
        text-align:center;
    }
    overflow: scroll;
    @media (min-width: @screen-sm-min) {
        overflow: visible;
    }
}