


//////////////////////////////
//////              Mixins
//////////////////////////////

// Hero Blocks

.hero-spacer{
    height:@xl-space + @l-space;
    @media (min-width: @screen-sm-min) {
            height:@l-space;
    }
    @media (min-width: @screen-lg-min) {
            height:@l-space + 10px;
    }
}
.hero-padding{
    padding:@l-space + @s-space 0px;
    h1{
        margin-top:0px;
    }
    @media (min-width: @screen-sm-min) {
            padding:@l-space + @s-space 0px;
    }
    @media (min-width: @screen-md-min) {
            padding:@xl-space + @l-space 0px;
    }
    @media (min-width: @screen-lg-min) {
            padding:@xxl-space 0px;
    }
}
.hero-padding-image{
    padding:@s-space 0px 0px 0px;
    @media (min-width: @screen-sm-min) {
            padding:@l-space 0px 0px 0px;
    }
    @media (min-width: @screen-md-min) {
            padding:@l-space 0px 0px 0px;
    }
    @media (min-width: @screen-lg-min) {
            padding:@xl-space 0px 0px 0px;
    }
}
.default-hero-block-001, .default-hero-block-002, .default-hero-block-003, .hero-block-with-image-001{
    p{
        font-weight:100;
        @media (min-width: @screen-md-min) {
            font-size:22px;   
        }
    }
}
// Deafult Hero Blocks One
.default-hero-block-001{
    .btn{
        display:block;
        margin-right: 0px;
        margin-left: 0px;
    }
    @media (min-width: @screen-sm-min) {
        .btn{
            display:inline;
            margin-right: 7px;
            margin-left: 7px;
        }
    }  
    @media (min-width: @screen-md-min) {
        .btn{
            display:inline;
            margin-right: 15px;
            margin-left: 15px;
        }
    }  
}
// Deafult Hero Blocks Two
.default-hero-block-002{
    text-align:center;
    position: relative;
    padding:60px 0px 0px 0px;
    h1, p, a{
        text-align:center;
    }
    background-color: #ffffff;
    .btn{
        display:block;
    }
    @media (min-width: @screen-sm-min) {
        h1{
            margin-top: @xs-space;
        }
        text-align:left;
        h1, p, a{
            text-align:left;
        }
        .btn{
            margin-left: 0px;
            margin-bottom:0px;
            display:inline;
            margin-right: 15px;
            margin-bottom:0px;
        }
    }
    @media (min-width: @screen-md-min) {
        h1{
            margin-top: @l-space;
        }
        text-align:left;
        h1, p, a{
            text-align:left;
        }
        .btn{
            margin-left: 0px;
            margin-bottom:0px;
            display:inline;
            margin-right: 15px;
            margin-bottom:@l-space;
        }
    }
    @media (min-width: @screen-lg-min) {
        .btn{
            margin-right: 30px;
            margin-left: 0px;
        }
    }
}
// Deafult Hero Blocks Three
.default-hero-block-003{
    padding:60px 0px 0px 0px;
    .btn{
        display:block;
        margin-right: 0px;
        margin-left: 0px;
    }
    @media (min-width: @screen-sm-min) {
        .btn{
            display:inline;
            margin-right: 7px;
            margin-left: 7px;
            margin-bottom:@l-space;
        }
        padding:@l-space 0px 0px 0px;
    }
    @media (min-width: @screen-md-min) {
        padding:@xl-space 0px 0px 0px;
        .btn{
            margin-right: 15px;
            margin-left: 15px;
        }
    } 
}
// Hero Blocks with background image
.hero-block-with-image-001{
    position:relative;
    z-index:1;
    .btn{
        display:block;
        margin-right: 0px;
        margin-left: 0px;
    }
    .image-frame{
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        z-index:-1;
        margin:0px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
    }
    @media (min-width: @screen-sm-min) {
        .btn{
            display:inline;
            margin-right: 7px;
            margin-left: 7px;
        }
    }  
    @media (min-width: @screen-md-min) {
        .btn{
            display:inline;
            margin-right: 15px;
            margin-left: 15px;
        }
    }  
}


//////////////////////////////
//////          Hero Classes
//////////////////////////////



.hero-block-001{
    .hero-padding;
    .default-hero-block-001;
    h1, p{
        color:#ffffff;
    }
    background-color:@theme-primary; 
}

.hero-block-002{
    .hero-padding;
    .default-hero-block-001;
    h1{
        color:@headings-color;
    }
    background-color:@blue-light; 
}

.hero-block-003{
    .hero-padding;
    .default-hero-block-001;
    h1{
        color:@headings-color;
    }
    background-color:#ffffff;
}

.hero-block-004{
    .hero-padding-image;
    .default-hero-block-002;
    h1, p, a{
        color:#ffffff;
    }
    background-color:@theme-primary;
}

.hero-block-005{
    .hero-padding-image;
    .default-hero-block-002;
    background-color:@blue-light;
}

.hero-block-006{
    .hero-padding-image;
    .default-hero-block-002;
    background-color: #ffffff;
}

.hero-block-007{
    .hero-padding-image;
    .default-hero-block-003;
    h1, p{
        color:#ffffff;
    }
    .image-box{
        img{
            width:100%;
        }
    }
    background-color:@theme-primary;
}

.hero-block-008{
    .hero-padding-image;
    .default-hero-block-003;
    h1{
        color:@headings-color;
    }
    .image-box{
        img{
            width:100%;
        }
    }
    background-color:@blue-light;
}

.hero-block-009{
    .hero-padding-image;
    .default-hero-block-003;
    h1{
        color:@headings-color;
    }
    .image-box{
        img{
            width:100%;
        }
    }
    background-color:#ffffff;
}

.hero-block-010{
    .hero-padding;
    .hero-block-with-image-001;
    h1, p{
        color:#ffffff;
    }
    background-color:@theme-primary;
    .image-frame{

    }
}

.hero-block-011{
    .hero-padding;
    .hero-block-with-image-001;
    h1, p{
        color:@headings-color;
    }
    background-color:@blue-light;
    .image-frame{

    }
}

.hero-block-012{
    .hero-padding;
    .hero-block-with-image-001;
    h1, p{
        color:#ffffff;
    }
    background-color:@blue-light;
    .btn{
        margin-right: 30px;
        margin-left: 0px;
    }
    .image-frame{

    }
}

.hero-block-013{
    .hero-padding;
    .hero-block-with-image-001;
    h1, p{
        color:@headings-color;
    }
    background-color:@blue-light;
    .btn{
        margin-right: 30px;
        margin-left: 0px;
    }
    .image-frame{

    }
}

.hero-block-014{
    .hero-padding;
    .hero-block-with-image-001;
    h1{
        @media (min-width: @screen-sm-min) {
        }
        @media (min-width: @screen-md-min) {
            font-size:62px;
        }
    }
    h1, p{
        color:#ffffff;
    }
    background-color:@theme-primary;
    @media (min-width: @screen-md-min) {
        padding:206px 0px;
    }
    .btn{
        margin-right: 30px;
        margin-left: 0px;
    }
    .image-frame{

    }
}