.carousel-block{
    .carousel-control{
        background-image:none;
        opacity:1;
        text-shadow:none;
    }
}

// Carousel Block 001
.carousel-block-001{
    .carousel-block;
    .carousel-inner{
        height: auto;
        padding: 0px;
        @media (min-width: @screen-sm-min) {
            height: auto;
        }
        @media (min-width: @screen-md-min) {
            height: 260px;
        }
    }
    .carousel-control{
        color: @gray;
        width: 70px;
    }
}

// Carousel Block 002 and 3 and 4
.carousel-block-002{
    .carousel-block;
    .large-quote-block;
    background-color:@blue-light;
    .carousel-control{
        color: @gray-darker;
    }
}
.carousel-block-003{
    .carousel-block;
    .large-quote-block;
    background-color:@brand-primary;
    h2, h3, p{
        color:#ffffff;
    }
    .carousel-control{
        color: #ffffff;
    }
}
.carousel-block-004{
    .carousel-block;
    .large-quote-block;
    background-color:@theme-primary;
    h2, h3, p{
        color:#ffffff;
    }
    .carousel-control{
        color: #ffffff;
    }
}
.carousel-block-002, .carousel-block-003, .carousel-block-004{
    .carousel-inner{
        .item{
            padding: 15px 35px;
        }
    }
    .carousel-control{
        width: 30px;
    }
    .carousel-control.left{
        left:20px;
    }
    .carousel-control.right{
        right:20px;
    }
    @media (min-width: @screen-sm-min) {
        .carousel-control{
            width: 70px;
        }
        .carousel-control.left{
            left:-70px;
        }
        .carousel-control.right{
            right:-70px;
        }
    }
    @media (min-width: @screen-md-min) {
        .carousel-control.left{
            left:-100px;
        }
        .carousel-control.right{
            right:-100px;
        }
    }
}

