// Article Blocks



p.meta i{
    font-size: 15px;
    bottom: -1px;
    position: relative;
    margin-right:3px;
        @media (min-width: @screen-sm-min) {
            font-size: 18px;
            bottom: -2px;
            margin-right:4px;
        }
}

.article-block-001, .article-block-002{
    p.meta {
        display: inline-block;
        padding: 0px 4px;
        margin: 5px 0px;
        font-size: small;
            @media (min-width: @screen-sm-min) {
                padding:0px @xs-space;
                margin:@xs-space 0px;
            }
    }
}

.article-block-002{
    h2{
            @media (min-width: @screen-md-min) {
                min-height:92px;
            }
    }
    .post{
        margin-bottom: @xs-space;
            @media (min-width: @screen-sm-min) {
                margin-bottom: @s-space;
            }
    }
}

.article-block-003{
    text-align:center;
    margin-bottom: @s-space;
        @media (min-width: @screen-sm-min) {
            text-align:left;
        }
    p.meta {
        display: inline-block;
        padding-right: 10px;
        font-size: small;
        padding: 0px 4px;
        margin: 5px 0px;
        font-size: small;
            @media (min-width: @screen-sm-min) {
                padding:0px @xs-space 0px 0px;
                margin:@xs-space 0px;
            }
    }
    .post-thumbnail{
	    height:150px;
	    margin-bottom: @xs-space;
	    width:100%;
	    background-color:#efefef;
	    @media (min-width: @screen-sm-min) {
            height:295px;
        }
    }
}

.article-block-004, .article-block-005{
    padding:0px;
    a:hover, a:visited, a:focus{
        text-decoration:none;
    }
    .row{
        margin:0px;
    }
    .dark-primary{
        background-color: darken(@brand-primary, 10%);
        &:hover{
            .ease-out;
                background-color: darken(@brand-primary, 15%);
            }
    }
    .primary-color{
        background-color: @brand-primary;
            &:hover{
                .ease-out;
                    background-color: darken(@brand-primary, 5%);
                }
    }
    .post{
        cursor: pointer;
        position:relative;
        padding: @s-space;
        min-height: 200px;
            @media (min-width: @screen-sm-min) {
                height:255px;
            }
            @media (min-width: @screen-sm-min) {
                height:310px;
            }
        p{
            font-size:small;
        }
        h3{
            padding: @s-space 0px;
        }
        .meta-tags{
            color:@gray-darker;
        }
        .meta-author{
            position:absolute;
            bottom: @xs-space;
        }
        .meta-author, h3{
            color: #ffffff;
        }
        .meta-author .avatar{
            position:relative;
            top:-2px;
            margin-right:5px;
            img{
                    border-radius: 50px;
                    -moz-border-radius: 50px;
                    -webkit-border-radius: 50px;
            }
        }
        .meta-tags .fa{
            margin-right:5px;
        }
    }
}

.article-block-005{
    .no-margin{
        padding:0px;
    }
    .image-frame{
	    margin:0px;
	    height: 182px;
	    width:100%;
	    background-color: #cccccc;
	        @media (min-width: @screen-sm-min) {
                min-height:620px;
            }
	        @media (min-width: @screen-md-min) {
                height:512px;
            }
    }
}


.article-block-006{
    padding:@m-space 0px;
    @media (min-width: @screen-sm-min) {
        padding:@xl-space 0px;
    }
    position:relative;
    h2{
        color:#ffffff;
    }
    .image-frame {
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0px;
        position: absolute;
        z-index: -100;
        left: 0px;
    }
    .post{
        cursor: pointer;
        position:relative;
        padding: 15px 15px 40px 15px;
        margin-bottom:@s-space;
            @media (min-width: @screen-sm-min) {
                margin-bottom:0px;
                height:255px;
            }
            @media (min-width: @screen-sm-min) {
                height:310px;
            }
        .border-radius;
        .box-shadow(0px, 3px, 0px, darken(@btn-primary-bg, 20%));
        background-color: @brand-primary;
            &:hover{
                .ease-out;
                    background-color: darken(@brand-primary, 5%);
                }
        p{
            font-size:small;
        }
        h3{
            padding: @s-space 0px;
        }
        .meta-tags{
            color:@gray-darker;
        }
        .meta-author{
            position:absolute;
            bottom: @xs-space;
        }
        .meta-author, h3{
            color: #ffffff;
        }
        .meta-author .avatar{
            position:relative;
            top:-2px;
            margin-right:5px;
        }
        .meta-tags .fa{
            margin-right:5px;
        }
    }
}

.article-block-007{
    .post-text{
        padding:0px;
        .post{
            margin-bottom:0px;
            .border-radius(5px 5px 0px 0px);
            @media (min-width: @screen-sm-min) {
                .border-radius(5px 0px 0px 5px);
                margin-bottom:30px;
            }
        }
    }
    .post-image{
        padding:0px;
        .image-frame{
            margin-bottom:20px;
            .border-radius(0px 0px 5px 5px);
            @media (min-width: @screen-sm-min) {
                margin-bottom:30px;
                .border-radius(0px 5px 5px 0px);
            }
        }
    }
    .image-frame{
        margin:0px;
        .box-shadow(0px, 3px, 0px, @gray-dark);
        height: 100px;
        width: 100%;
        @media (min-width: @screen-sm-min) {
            height: 310px;
        }
    }
    .post{
        cursor: pointer;
        position:relative;
        padding: 15px 15px 40px 15px;
        margin-bottom:@s-space;
            @media (min-width: @screen-sm-min) {
                padding: @s-space;
                margin-bottom:0px;
                height:255px;
            }
            @media (min-width: @screen-sm-min) {
                height:310px;
            }
        .border-radius;
        .box-shadow(0px, 3px, 0px, darken(@btn-primary-bg, 20%));
        background-color: @brand-primary;
            &:hover{
                .ease-out;
                    background-color: darken(@brand-primary, 5%);
                }
        p{
            font-size:small;
        }
        h3{
            padding: @s-space 0px;
        }
        .meta-tags{
            color:@gray-darker;
        }
        .meta-author{
            position:absolute;
            bottom: @xs-space;
        }
        .meta-author, h3{
            color: #ffffff;
        }
        .meta-author .avatar{
            position:relative;
            top:-2px;
            margin-right:5px;
            img{
                border-radius: 21px;
                -webkit-border-radius: 21px;
                -moz-border-radius: 21px;
            }
        }
        .meta-tags .fa{
            margin-right:5px;
        }
    }
}
