// Dummy Images - Should either be removed or replaced with real images

.image-frame {
  position: relative;
  overflow: hidden;
}

.image-frame img {
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    min-width: 100%;
    height: 100%;
    @media (min-width: @screen-sm-min) {
        min-height: 100%;
        height:auto;
    }
}

/*
.image-frame, .small-image-frame{
    margin: @s-margin 0px;
    background-color: transparent;
    width:100%;
    overflow:hidden;
}
.image-frame img{
    width:100%;
    height:auto;
}
*/
.image-frame-circle{
    border-radius:110px;
    margin: @s-margin auto;
    height: 80px;
    width: 80px;
    overflow: auto;
    background-color: transparent;
    img{
        width:100%;
        height:100%;
    }
    @media (min-width: @screen-sm-min) {
        margin-top:0px;
        height: 110px;
        width:110px;
    }
    @media (max-width: @screen-sm-min) {
        height: 50px;
        width: 50px;
    }
}

.default-hero-block-002{
    .image-box{
        height: 200px;
        background-color: transparent;
        width:100%;
        margin-top:30px;
        margin-bottom: 0px;
        overflow:hidden;
        img{
            width:100%;
        }
        @media (min-width: @screen-sm-min) {
            height:395px;
        } 
        @media (min-width: @screen-md-min) {
            margin-top:0px;
            height: 420px;
            position: relative;
            bottom: 0px;
        }  
        @media (min-width: @screen-lg-min) {
            height:460px;
            position: relative;
            bottom: 0px;
        }   
    }
}
.default-hero-block-003{
    .image-box{
        margin-top:30px;
    }
}
/*
.default-hero-block-003 .image-frame{
    height: 150px;
    background-color: transparent;
    width:100%;
    margin-top:15px;
    margin-bottom:0px;
    @media (min-width: @screen-sm-min) {
        margin-top: 15px;
        height:382px;
        position: relative;
        bottom: 0px;
    }
    @media (min-width: @screen-md-min) {
        margin-top: @l-space;
    }
}





.dummy-image-3{
    height: 200px;
    margin: @s-margin 0px;
    background-color: transparent;
    width:100%;
    position: relative;
    bottom: 0px;
    @media (min-width: @screen-sm-min) {
        height: 258px;
    }   
    @media (min-width: @screen-md-min) {
        height: 508px;
    }  
}
*/
