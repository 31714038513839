// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700|Montserrat:400,700);

// Bootstrap Less -- To update just recompile with the latest Bootstrap
@import "bootstrap/bootstrap.less";

@import "dummy-images.less";

// Components
@import "navbar.less";

// Foundation Theme Variables
@import "variables.less";
@import "type.less";
@import "buttons.less";
@import "mixins/box-shadow.less";

// Block Library
@import "blocks/blocks.less";
@import "blocks/content-blocks.less";
@import "blocks/action-blocks.less";
@import "blocks/footer-blocks.less";
@import "blocks/carousel-blocks.less";
@import "blocks/hero-blocks.less";
@import "navbar.less";
@import "blocks/article-blocks.less";
@import "blocks/pricing-blocks.less";

// Themes - To change theme of less files, uncomment selected theme and recompile docs 

@import "themes/theme-001.less";
/*@import "themes/theme-002.less";*/
/*@import "themes/theme-003.less";*/
/*@import "themes/theme-004.less";*/
/*@import "themes/theme-005.less";*/


// FRAMEWORK STYLING - ORGANISE THIS EVENTUALLY

.container-fluid{
    max-width:1170px;
}
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: @screen-sm-min) {
        padding-left: 30px;
        padding-right: 30px; 
    }
}
.label {
    padding: .2em .6em .3em;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0px;
}