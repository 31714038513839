// Content Blocks

// Content Module 001 - Centered Text
.content-block-001{
}

// Content Module 002 and 003 - Text on left, image on right
.content-block-002 .row{
    display: flex;
    flex-direction: column;
    .one{
        order:2;
    }
    .two{
        order:1;
    }
    @media (min-width: @screen-sm-min) {
        display: block;
    }   
}
.content-block-002, .content-block-003, .content-block-004{
    .text-box{
        display:table;
        height: auto;
        .middle-aligned-text{
            display:table-cell;
            vertical-align:middle;
        }
        @media (min-width: @screen-sm-min) {
            height: 340px;
        }
        @media (min-width: @screen-md-min) {
            height: 384px;
        }   
    }
    .image-frame{
        height: 148px;
        @media (min-width: @screen-sm-min) {
            height: 340px; 
        }
        @media (min-width: @screen-md-min) {
            height: 376px; 
        }
    }
}

// Content Module 004
.content-block-004 .row{
    display: flex;
    flex-direction: column;
    .one{
        order:2;
    }
    .two{
        order:1;
    }
    .three{
        order:3;
    }
    @media (min-width: @screen-sm-min) {
        display: block;
    }   
}

// Content Module 005
.content-block-005{
    position:relative;
    p{
        @media (min-width: @screen-sm-min) {
            height:249px;
        }
        @media (min-width: @screen-md-min) {
            height:162px;
        }
        @media (min-width: @screen-lg-min) {
            height:162px;
        }
    }
    @media (min-width: @screen-sm-min) {
        h2{
            margin-top:0px;
        }
        .small-img-left{
            padding-right: 15px;
        }
        .small-img-right{
            padding-left: 15px;
        }
    }  
    .image-frame{
        height: 200px;
        img{
            width:100%;
            height:auto;
        }
        @media (min-width: @screen-sm-min) {
            height: 443px; 
            img{
                min-width:100%;
                width:auto;
                height:100%;
            }
        }
        @media (min-width: @screen-md-min) {
            height: 376px; 
        }
    }
    .small-img-left{
        padding-right: 7px;
    }
    .small-img-right{
        padding-left: 7px;
    }
    .small-image-frame{
        height: 100px;
        @media (min-width: @screen-sm-min) {
            height: 145px; 
        }
        position:relative;
        overflow:hidden;
        img {
            position: absolute;
            top: -9999px;
            bottom: -9999px;
            left: -9999px;
            right: -9999px;
            margin: auto;
            min-width: 100%;
            min-height: 100%;
        }
    } 
}

// Content Module 006
.content-block-006{
    .image-frame-circle{
        border-radius:0px;
    }
    .padding-right{
            padding-right: 6px;
        }
        .padding-left{
            padding-left: 6px;
        }
        @media (min-width: @screen-sm-min) {
            .padding-right{
                padding-right: 15px;
            }
            .padding-left{
                padding-left: 15px;
        }
    }
    .stats{
        .dummy-image-circle-1{
              margin-top: 5px;
        }
        h1{
            margin-bottom:0px;
        }   
        p{
            margin-top:0px;
            @media (min-width: @screen-sm-min) {
                
            }
        }
    }
}

.content-block-007{
    .bio-column .bio{
        padding-left:0px;
    }
    @media (min-width: @screen-sm-min) {
        .bio-column .bio{
            padding-left:15px;
        }
        h2, h3, p, a{
            text-align:center;
        }
    }
    @media (min-width: @screen-md-min) {
        h2, p, a{
            text-align:left;
        }
        .bio{
            h3, p, a{
                text-align:center;
            }
        }
    }
}

// Content Module 008 - 009 - 010
.content-block-008, .content-block-009{
    .image-frame-circle{
        border-radius:0px;
    }
    h2{
        text-align:center;
    }
    h3, p, a{
        text-align:left;
    }
    .image-frame {
        height: 170px;
        margin-bottom:@s-space;
        @media (min-width: @screen-sm-min) {
            height: 530px;
        }   
        @media (min-width: @screen-md-min) {
            height: 565px;
        }
        @media (min-width: @screen-lg-min) {
            height: 508px;
        }
    }
    @media (max-width: @screen-sm-min) {
        .col{
            padding-left:0px;
        }
    }
    @media (min-width: @screen-sm-min) {
        h3, p, a{
            text-align:center;
        }
    }
}

// Content Module 010
.content-block-010{
    h2{
        text-align:center;
    }
    h3, p, a{
        text-align:left;
    }
    .image-frame {
        height: 170px;
        margin-bottom: 20px;
        img{
            height:auto;
            position:initial;
            width:100%;
        }
        @media (min-width: @screen-sm-min) {
            img{
                
            }
            height: 530px;
        }   
        @media (min-width: @screen-md-min) {
            height: 565px;
        }
        @media (min-width: @screen-lg-min) {
            height: 508px;
        }
    }
    @media (max-width: @screen-sm-min) {
        .col{
            padding-left:0px;
        }
    }
    @media (min-width: @screen-sm-min) {
        h3, p, a{
            text-align:center;
        }
    }
}
.content-block-010 .outer-row{
    display: flex;
    flex-direction: column;
    .one{
        order:2;
    }
    .two{
        order:1;
    }
    .three{
        order:3;
    }
    @media (min-width: @screen-sm-min) {
        display: block;
    }   
}

// Content Module 011
.content-block-011{
    a{
        text-decoration:none ;
    }
    .large-margin-bottom{
        margin-bottom:@s-space;
    }
    h2{
        text-align:center;
    }
    .featured-content-box{
        border: 1px solid @gray-light;
        .box-shadow(0px, 3px, 0px, @gray-light);
        .border-radius (3px);
        overflow: auto;
    }
    .f-c-b-image{
        height: 153px;
        background-color: @gray-light;
        position: relative;
        overflow:hidden;
        img{
            position: absolute;
            top: -9999px;
            bottom: -9999px;
            left: -9999px;
            right: -9999px;
            margin: auto;
            min-width: 100%;
            min-height: 100%;
            @media (min-width: @screen-xs-min) {
                width:100%;
            }
            @media (min-width: @screen-sm-min) {
                width:auto;
            }
        }
        .labels{
            position: absolute;
            bottom: 8px;
            right:8px;
            .label{
                margin-left:4px;
            }
            @media (min-width: @screen-md-min) {
                bottom: 15px;
                right:15px;
                .label{
                    margin-left:7px;
                }
            }
        }
    }
    .f-c-b-title{
        padding: @s-space;
        h3{
            margin: 0px;
        }
        @media (min-width: @screen-sm-min) {
            padding: @s-space;
        }
        @media (min-width: @screen-md-min) {
            padding: @s-space;
        } 
        @media (min-width: @screen-lg-min) {
            min-height: 0;
            padding: @m-space;
        } 
    }
    @media (min-width: @screen-sm-min) {
        .large-margin-bottom{
            margin-bottom:@m-space + 3px;
        }
    }
}


// ======== LARGE QUOTE BLOCKS ==============//

.large-quote-block{
    padding: @m-space 0px;
    @media (min-width: @screen-sm-min) {
        padding: @xl-space 0px;
    }
    @media (min-width: @screen-md-min) {
        padding: @xl-space + @m-space 0px;
    } 
}

.content-block-012{
    .large-quote-block;
    background-color:@blue-light;
}

.content-block-013{
    .large-quote-block;
    background-color: @brand-primary;
    h1, h2, h3, h4, p, a{
        color:#ffffff;
    }
}

.content-block-014{
    .large-quote-block;
    background-color: @theme-primary;
    h1, h2, h3, h4, p, a{
        color:#ffffff;
    }
}

.content-block-015 .image-box{
    margin-bottom: 30px;
    background-color: #FFFFFF;
    width: 100%;
    overflow: hidden;
    text-align: center;
    img{
        width: 100%;
    }
    &:hover{

    }
}

.content-block-016, .content-block-017{
    padding:0px;
    .container-fluid{
        max-width:100%;
        padding-left: 0px;
        padding-right: 0px;
        overflow:hidden;
    }
    .overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.42);
        z-index: 1000;
        color: #ffffff;
        opacity:0;
        text-align:center;
         &:hover{
            opacity:1;
            -webkit-transition: 500ms ease-out;
            -moz-transition: 500ms ease-out;
            -o-transition: 500ms ease-out;
            transition: 500ms ease-out;
         }
         .fa{
            margin: -17px auto auto -15px;
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 35px;
         }
    }
    .image-wrap{
        margin:0px;
        padding:0px;
        img{
            @media (min-width: @screen-lg-min) {
                width:100%;
            }
        }
    }
    .image-frame{
        height:150px;
        width:100%;
        background-color:@brand-primary;
        &:hover{

        }
        @media (min-width: @screen-sm-min) {
            height:250px;
        }
        @media (min-width: @screen-md-min) {
        }
        @media (min-width: @screen-lg-min) {
        }
    }
}
