.box-shadow (@x: 0px, @y: 3px, @blur: 5px, @color: #0099ff) {
	-webkit-box-shadow: @x @y @blur @color;
	-moz-box-shadow: @x @y @blur @color;
	box-shadow: @x @y @blur @color;
}

.ease-out {
    -webkit-transition: background-color 250ms ease-in 0s;
    -moz-transition: background-color 250ms ease-in 0s;
    -o-transition: background-color 250ms ease-in 0s;
    transition: background-color 250ms ease-in 0s;
}

.border-radius (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;
}