.navbar{
    border:0px;
    border-radius: 0px;
    margin-bottom:0px;
    ul li{
        margin:0px;
    }
}

.theme-navbar{
    .navbar-brand{
        font-weight:900;
        font-size:22px;
        letter-spacing:1px;
        margin-top:0px;
        margin-bottom:0px;
        text-decoration:none;
        @media (min-width: @screen-sm-min) {
            font-size:24px;
            line-height: 29px;
        }
        @media (min-width: @screen-md-min) {
            font-size:32px;
            line-height: 53px;
        }
    }
    ul.nav{
        li{
            a{
                text-decoration:none;
                margin:0px;
                padding: @s-space @s-space;
                &:hover{
                .ease-out;
                }
                @media (min-width: @screen-md-min) {
                    padding:@m-space - 1px @s-space;
                }
            }
            @media (min-width: @screen-sm-min) {
                display:inline-block;
            }
        }
        @media (min-width: @screen-sm-min) {
            float:right;
        }
    }
    .navbar-nav {
        margin: 0px -15px;
    }
    .navbar-toggle{
        margin:0px;
        height:50px;
        border-radius:0px;
        padding:0px 15px;
        border-color: transparent;
        .icon-bar{
            width: 20px;
            height: 3px;
            border-radius: 0px;
        }
    }
    .navbar-collapse{
        border-top:0px;
    }
    @media (min-width: @screen-sm-min) {
        .navbar-toggle{
            height:55px;
            padding:0px 15px;
            .icon-bar{
                width: 25px;
                height: 3px;
                border-radius: 0px;
            }
        }
    }
    @media (min-width: @screen-md-min) {
        height: 80px;
        padding: 0px;
    }
}

.navbar-default{
    background-color: @theme-primary;
    .navbar-brand{
        color:#ffffff;
        &:hover{
            color:@brand-primary;
        }
    }
    ul.nav{
        li{
            a{
                color:#ffffff;
                &:hover{
                    color:#ffffff;
                    background-color:@theme-primary;
                }
            }
        }
        li.cta{
            background-color:@brand-primary;
            a{
                &:hover{
                    background-color:@btn-primary-border;
                }   
            }
        }
    }
    .navbar-toggle{
        background-color:#ffffff;
        border-color: transparent;
        .icon-bar{
            background-color:@theme-primary;
        }
    }
    @media (min-width: @screen-sm-min) {
        .navbar-toggle{
            .icon-bar{
                background-color:@theme-primary;
            }
        }
    }
}




// == NAVBAR 001 == //

.navbar-002{
    .theme-navbar;
    .navbar;
    background-color: #ffffff;
    .navbar-brand{
        color:@gray-darker;
        &:hover{
            color:@brand-primary;
        }
    }
    ul.nav{
        li{
            a{
                color:@gray;
                &:hover{
                    color:darken(@gray, 5%);
                    background-color:@blue-light;
                }
            }
        }
        li.cta{
            background-color:@brand-primary;
            a{
                color:#ffffff;
                &:hover{
                    background-color:@btn-primary-border;
                }   
            }
        }
    }
    .navbar-toggle{
        background-color:#ffffff;
        .icon-bar{
            background-color:@theme-primary;
        }
    }
    @media (min-width: @screen-sm-min) {
        .navbar-toggle{
            .icon-bar{
                background-color:@theme-primary;
            }
        }
    }
}


// == NAVBAR 001 == //

.navbar-003{
    .theme-navbar;
    .navbar;
    background-color: @blue-light;
    .navbar-brand{
        color:@gray-darker;
        &:hover{
            color:@brand-primary;
        }
    }
    ul.nav{
        li{
            a{
                color:@gray;
                &:hover{
                    color:darken(@gray, 5%);
                    background-color:darken(@blue-light, 5%);
                }
            }
        }
        li.cta{
            background-color:@brand-primary;
            a{
                color:#ffffff;
                &:hover{
                    background-color:@btn-primary-border;
                }   
            }
        }
    }
    .navbar-toggle{
        background-color:#ffffff;
        .icon-bar{
            background-color:@theme-primary;
        }
    }
    @media (min-width: @screen-sm-min) {
        .navbar-toggle{
            .icon-bar{
                background-color:@theme-primary;
            }
        }
    }
}

/* TRANSPARENT NAVBARS */

.navbar-transparent-light{
    .theme-navbar;
    .navbar;
    position:absolute;
    z-index:10000;
    width:100%;
    background-color: transparent;
    .navbar-nav{
        background-color:@theme-primary;
    }
    .navbar-brand{
        color:#ffffff;
        &:hover{
            color:@brand-primary;
        }
    }
    ul.nav{
        li{
            a{
                color:#ffffff;
                &:hover{
                    color:#ffffff;
                    background-color:@theme-primary;
                }
            }
        }
        li.cta{
            background-color:@brand-primary;
            a{
                &:hover{
                    background-color:@btn-primary-border;
                }   
            }
        }
    }
    .navbar-toggle{
        background-color:#ffffff;
        border-color: transparent;
        .icon-bar{
            background-color:@theme-primary;
        }
    }
    @media (min-width: @screen-sm-min) {
        .navbar-nav{
            background-color: transparent;
        }
        background-color: transparent;
        .navbar-toggle{
            .icon-bar{
                background-color:@theme-primary;
            }
        }
    }
}


.nav > li > a:hover, .nav > li > a:focus{
    background-color: transparent;
}

.navbar-001{
    .navbar;
    .navbar-default;
    .theme-navbar;
    border-radius: 0px !important;
    -moz-border-radius: 0px;
    -webit-border-radius: 0px;
}