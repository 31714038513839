.gray-stripe{
    background-color: lighten(@gray-darker, 78%);
}

.block-title-seperator{
    background-color:#000000;
    padding:5px 0px 5px 15px;
    p{
        font-size:14px;
        color:#ffffff;
        line-height:18px;
    }
}

section{
    padding:@s-space 0px;
    @media (min-width: @screen-sm-min) {
            padding:@l-space 0px;
    }
    @media (min-width: @screen-md-min) {
            padding:@l-space 0px;
    }
    @media (min-width: @screen-lg-min) {
            padding:@l-space + 10px 0px;
    }
}
.small-section-spacer{
    height:@xs-space;
    @media (min-width: @screen-sm-min) {
            height:@xs-space;
    }
    @media (min-width: @screen-lg-min) {
            height:@xs-space;
    }
}
.section-spacer{
    height:20px;
    @media (min-width: @screen-sm-min) {
        height:@l-space + 10px;
    }
}

@media (min-width: @screen-sm-min) {
    .large-margin-bottom{
        margin-bottom: @s-space;
    }
}
@media (min-width: @screen-md-min) {
    .large-margin-bottom{
        margin-bottom: @l-space;
    }
}
