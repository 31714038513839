.footer-block{
    padding:0px;
    h3, p, a{
        color:#ffffff;
    }
    ul.site-links{
        padding:0px;
        margin: 11px 0px;
        li{
            display:inline;
            list-style-type:none;
            margin-right: @s-space;
        }
        @media (min-width: @screen-sm-min) {
            margin-left: @m-space;
        } 
    } 
    .utility-bar{
        padding:@s-space 0px;
        background-color:@gray-darkest;
        .text-right{
            text-align:left;
        }
    }
    @media (min-width: @screen-sm-min) {
        .utility-bar .text-right{
            text-align:right;
        }
    } 
}

.footer-block-001{ 
    .footer-block;
}

.footer-block-002{  
    .footer-block;
    .footer{
        background-color: @gray-darker;
        padding:@l-space 0px @l-space 0px;
        @media (min-width: @screen-sm-min) {
            padding:@l-space 0px @xl-space 0px;
        }
        ul{
            padding:0px;
            margin: 11px 0px;
            li{
                list-style-type:none;
            }
        }
    }
    .utility-bar{
        padding:@s-space 0px;
        background-color:@gray-darkest;   
    }
    @media (min-width: @screen-sm-min) {

    } 
}

