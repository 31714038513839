// Content Module 001 - Centered Text

.action-block-001, .action-block-002, .action-block-003, .action-block-005{
    h2, p{
        color:#ffffff;
    }
    .btn-primary{
        background-color: transparent;
        border:1px solid #ffffff;
        .box-shadow(0px, 0px, 0px, darken(@btn-primary-bg, 20%));
    }
    background-color:@brand-primary;
    .text-box{
        display:table;
        height: auto;
        .middle-aligned-text{
            display:table-cell;
            vertical-align:middle;
        }
        @media (min-width: @screen-sm-min) {
            height: 384px;
        }   
    }
}
.action-block-002{
    .image-frame{
        height:150px;
        background-color:transparent;
        @media (min-width: @screen-sm-min) {
            height: 380px;
        }
        @media (min-width: @screen-md-min) {
            height: 384px;
            .text-box{
                display:table;
                height:380px;
            }
            .middle-aligned-text{
                display:table-cell;
                vertical-align:middle;
            }
        }
        @media (min-width: @screen-lg-min) {
            height: 384px;
        }
    }
}
.action-block-003{
    @media (max-width: @screen-xs-min) {
        text-align:center;
    }
    @media (min-width: @screen-sm-min) {
        .button-box{
            display:table;
            height:130px;
        }
        .middle-aligned-button{
            display:table-cell;
            vertical-align:middle;
        }
    }
}

.action-block-004, .action-block-005{
    background-color:@brand-primary;
    .fa{
        color: darken(@btn-primary-bg, 20%);
    }
    a{
        text-decoration:underline;
        &:hover{
            text-decoration:none;
        }
    }
    ul{
        padding:0px;
    }    
    ul li{
        list-style:none;
        line-height: 1.3;
    }
    h1, h2, h3, h4, h5, p, a, li{
        color:#ffffff;
    }
    .fa{
        margin-right:@xs-space;
        text-align:center;
        width: 25px;
    }
    ul.social-links li{
        display:inline;
    }
    ul.social-links .fa{
        margin-right:@s-space;
        text-align:center;
    }
}

.action-block-004{
    overflow: hidden;
    padding-bottom: 0px;
    .container-fluid{
        padding-left: 0px;
    }
    .inner-wrapper{
        max-width: 1170px;
        margin: auto;
        background-color: #FF0047;
    }
    .image-holder{
        bottom: -6px;
        position: relative;
        margin-top:@s-space;
        height:150px;
        width:150%;
        margin-left:-15px;
            @media (min-width: @screen-sm-min) {
                    height:530px;
                    margin-top:-46px;
            }
            @media (min-width: @screen-md-min) {
                    height:530px;
                    margin-top:-46px;
            }
            @media (min-width: @screen-lg-min) {
                    height:510px;
                    margin-top:-56px;
            }
    }
    @media (max-width: @screen-xs-min) {
    }
    @media (min-width: @screen-sm-min) {
        .container-fluid{
            padding-left: 15px;
        }
    }
}
.action-block-005{
    .col-2{
        margin-top:30px;
        @media (min-width: @screen-sm-min) {
            margin-top:60px;
        }
    }
    label{
        color:#ffffff;
    }
    .image-holder{
        img{
            width:100%;
        }
    }
    .form-control{
        border:0px;
    }
    .form-control {
        display: block;
        width: 100%;
        color:#ffffff;
        height: 36px;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.42857143;
        background-color: transparent;
        background-image: none;
        box-shadow: none;
        border-bottom: 1px solid darken(@brand-primary, 20%);
        border-radius: 0px;
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        border-top: 0px;
        padding-left: 0px;
        border-left: 0px;
        border-right: 0px;
    }
    @media (max-width: @screen-xs-min) {
    }
    @media (min-width: @screen-sm-min) {
    }
    input::-webkit-input-placeholder {
        color:  darken(@btn-primary-bg, 20%) !important;
    }

    input:-moz-placeholder { /* Firefox 18- */
        color:  darken(@btn-primary-bg, 20%) !important;  
    }

    input::-moz-placeholder {  /* Firefox 19+ */
        color:  darken(@btn-primary-bg, 20%) !important;  
    }

    input:-ms-input-placeholder {  
        color:  darken(@btn-primary-bg, 20%) !important;  
    }  
}
.action-block-006{
    position:relative;
    iframe{
        width:100%;
    }
    .box{
        background-color:#ffffff;
        padding:@s-space;
        margin-bottom: @m-space;
        @media (min-width: @screen-md-min) {
            margin-bottom: 0px;
        }
        @media (min-width: @screen-sm-min) {
            min-height: 210px;
            padding:@m-space;
        }
        @media (min-width: @screen-md-min) {
            min-height: 215px;
        }
    }
    .last{
        margin-bottom:0px;
    }
    .image-frame {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    a{
        text-decoration:underline;
        &:hover{
            text-decoration:none;
        }
    }
    .fa{
        color: @brand-primary
    }
    ul{
        padding:0px;
    }    
    ul li{
        list-style:none;
    }
    .fa{
        margin-right:@xs-space;
        text-align:center;
        width: 25px;
    }
    ul.social-links li{
        display:inline;
    }
    ul.social-links .fa{
        margin-right: 10px;
        text-align:center;
        @media (min-width: @screen-md-min) {
            margin-right:@s-space;
        }
    }
}
.action-block-007{
    .col-2{
        margin-top:30px;
        @media (min-width: @screen-sm-min) {
            margin-top:0px;
        }
    }
    background-color:@gray-light;
    .fa{
        color: darken(@btn-primary-bg, 20%);
    }
    a{
        text-decoration:underline;
        &:hover{
            text-decoration:none;
        }
    }
    ul{
        padding:0px;
    }    
    ul li{
        list-style:none;
        line-height: 1.3;
    }
    .fa{
        margin-right:@xs-space;
        text-align:center;
        width: 25px;
    }
    ul.social-links li{
        display:inline;
    }
    ul.social-links .fa{
        margin-right:@s-space;
        text-align:center;
    }
    .form-control {
        display: block;
        width: 100%;
        height: 36px;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.42857143;
        background-color: transparent;
        background-image: none;
        box-shadow: none;
        border-bottom: 1px solid darken(@gray-light, 20%);
        border-radius: 0px;
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        border-top: 0px;
        padding-left: 0px;
        border-left: 0px;
        border-right: 0px;
    }
}
