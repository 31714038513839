@grid-float-breakpoint: @screen-desktop;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif:  'Roboto', Arial, sans-serif;
// @font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-base:        'Roboto', sans-serif;

@font-size-base:          16px;

//** By default, this inherits from the `<body>`.
@headings-font-family:    'Montserrat', sans-serif;
@headings-font-weight:    500;
@headings-line-height:    1.3;
@headings-color:          @gray-darker;

//## Typography Variables

@xs-margin: 5px;
@s-margin: 10px;
@m-margin: 15px;
@l-margin: 20px;


//== Spacing
//
//## These are the Foundation theme spacer variables for use in margins and padding etc...
@xxl-space: 160px;
@xl-space: 80px;
@l-space: 40px;
@m-space: 30px;
@s-space: 20px;
@xs-space: 10px;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

@gray-darkest:           #161B21;
@gray-darker:            #252B33;
@gray-dark:              lighten(@gray-darker, 20%);
@gray:                   #6F787B;
@gray-light:             #EBEFF1;
@blue-light:             #F1F8FB;

@theme-primary:         #263A51;
@brand-primary:         #00A6FF;
@brand-success:         #90E49D;
@brand-info:            #54D3FF;
@brand-warning:         #FECD52;
@brand-danger:          #FC6079;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);



//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

@btn-font-weight:                normal;

@btn-default-color:              #333;
@btn-default-bg:                 #fff;
@btn-default-border:             #ccc;

@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-secondary-color:              @gray;
@btn-secondary-bg:                 #ffffff;
@btn-secondary-border:             lighten(@gray, 45%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;